const queryHintCustomAttrFields = [
  "brand",
  "url",
  "is_tally",
  "price",
  "price_inc_vat",
  "offer_price",
  "offer_price_inc_vat",
  "availability_flag",
  "availability_reason",
  "cf_width",
  "unit",
  "offer_unit",
  "cf_thickness",
  "cf_treatment",
  "cf_grade",
];

const useSearchAndMerch = () => {
  const { BLOOMREACH_WIDGET_ID, BR_ENV_TYPE } = useRuntimeConfig().public;
  const { selectedBranchId } = useBranches();
  const bloomreachCookie = useCookie("_br_uid_2");
  const viewId = computed(() => (selectedBranchId.value ? `b_${selectedBranchId.value}` : "default"));
  const brUid = computed(() => (bloomreachCookie ? bloomreachCookie.value : "noBrCookie"));

  const fetchProductDetails = async (productId: string) => {
    const { data, error } = await useTimedAsyncData("findItemById", () =>
      useSdk().brSearch.findItemById({
        queryName: "findItemById",
        id: productId,
        queryHint: {
          viewId: viewId.value,
          brEnvType: BR_ENV_TYPE === "STAGING" ? "STAGING" : undefined,
          brUid2: brUid.value ?? undefined,
          customAttrFields: queryHintCustomAttrFields,
        },
      }),
    );
    // Handle error or missing data case
    if (error.value || !data.value) {
      throw createError({
        statusCode: 404,
        message: "Product not found",
        statusMessage: "No product data found",
        fatal: true,
      });
    }
    if (data.value && data.value.customAttrs) {
      data.value.customAttrs = data.value.customAttrs.map((attr: any) => {
        if (
          attr &&
          attr.values &&
          ["price", "price_inc_vat", "offer_price", "offer_price_inc_vat"].includes(attr.name)
        ) {
          return {
            ...attr,
            values: attr.values
              .filter((value: any): value is string => value !== null && value !== undefined)
              .map((value: any) => Number.parseFloat(value).toFixed(2)),
          };
        }
        return attr;
      });
    }
    return data.value;
  };

  const fetchRelatedProducts = async (productId: string) => {
    const { data, error } = await useTimedAsyncData("findItemsByWidget", () =>
      useSdk().brSearch.findItemsByWidget({
        pids: productId,
        queryHint: {
          viewId: viewId.value,
          brEnvType: BR_ENV_TYPE === "STAGING" ? "STAGING" : undefined,
          widgetId: BLOOMREACH_WIDGET_ID,
          brUid2: "noBrCookie",
          widgetType: "item",
          customAttrFields: queryHintCustomAttrFields,
          facetFieldFilters: [{ id: "availability_flag", values: ["1", "2", "4"] }],
        },
      }),
    );
    if (error.value) {
      throw createError({
        statusCode: 404,
        message: "page not found",
        statusMessage: "no page",
        fatal: false,
      });
    }
    return data.value;
  };

  return {
    fetchProductDetails,
    fetchRelatedProducts,
  };
};

export default useSearchAndMerch;
